import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Card, Button } from "components";

import { cancelShipment } from "redux/actions/shipment.action";
import { getASNs } from "redux/actions/asn.action";

const ContactCarrier = ({ carrier, cancelShipment, getASNs }) => {
  useEffect(getASNs);
  const { selectedCarrierId, carriers } = carrier;
  const selectedCarrier = carriers.find((c) => c.id === selectedCarrierId);
  const { name, address, city, state, zipcode, email, pick_up_window, phone } =
    selectedCarrier;
  return (
    <Card title="Contact Carrier" collapsible={false}>
      <p>Carrier contact information is below if needed.</p>
      <p>
        Name: <b>{name}</b>
      </p>
      <p>
        Pick up window: <b>{pick_up_window}</b>
      </p>
      <p>
        Address:
        <b>
          {address}, {city}, {state} {zipcode}
        </b>
      </p>
      <p>
        Phone: <b>{phone}</b>
      </p>
      <p>
        Email: <b>{email}</b>
      </p>
      <Button
        color="primary"
        className={"float-left"}
        btnSize="100px"
        onClick={cancelShipment}
      >
        Confirm
      </Button>
    </Card>
  );
};

export default connect(({ carrier }) => ({ carrier }), {
  cancelShipment,
  getASNs,
})(ContactCarrier);
