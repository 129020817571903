import React from "react";
import ActionIcon from "./components/ActionIcon";
import config from "config/config";

const cellRender = ({ data, context }) => {
  const shouldNotRender = () => {
    const asns = context.componentParent.props.shipment.asnsToShip;
    const shipment = context.componentParent.props.shipment;

     // Check if data.po.ship_to_store is 4 and replace it with 2
    if (data.po.ship_to_store === 4) data.po.ship_to_store = 2;
    // Check if data.po.ship_to_store is 3 and replace it with 1
    if (data.po.ship_to_store === 3) data.po.ship_to_store = 1;

    return (
      asns.includes(data) ||
      (shipment.shipFromWarehouse.id &&
        shipment.shipFromWarehouse.id !== data.warehouse.id) ||
      (shipment.shipToStore &&
        shipment.shipToStore !== data.po.ship_to_store &&
        (!config
          .getTogetherAllowedStores()
          .includes(Number(shipment.shipToStore.toString().split("/")[0])) ||
          !config
            .getTogetherAllowedStores()
            .includes(data.po.ship_to_store))) ||
      shipment.asnsToShip.map((asn) => asn.id).includes(data.id)
    );
  };
  if (shouldNotRender()) {
    return null;
  }
  return (
    <ActionIcon
      click={(e) => {
        e.preventDefault();
        context.componentParent.addASNToShipTable(data);
      }}
      icon={"icon-add"}
    />
  );
};
// icon-box-remove
export const AddASNToShipment = {
  headerName: "Add to Shipment",
  cellRendererFramework: cellRender,
  sortable: false,
  cellStyle: { display: "flex", "justify-content": "center" },
  width: 140,
};
